import ProfileCard from "components/ProfileCard"
import format from "date-fns/format"
import isYesterday from "date-fns/isYesterday"
import { Amplify } from "aws-amplify"
import { AWS_CONFIG } from "../../../aws-config"
import React, { useEffect } from "react"
import styled, { css } from "styled-components"
import {
  FaRegThumbsUp,
  FaRegThumbsDown,
  FaRegEdit,
  FaRegTrashAlt,
} from "react-icons/fa"
import FilePreview from "./FilePreview"
import { ForumPost } from "api/types"

const Container = styled.div`
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 16px;
  margin-bottom: 16px;
`
const Content = styled.div`
  margin-left: 46px;
`
const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`
const FooterSection = styled.div`
  display: flex;
  align-items: center;
`
const FooterText = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: ${props => props.theme.colors.secondary};
  margin-left: 6px;
  margin-right: 16px;
  cursor: pointer;
`
const FooterLink: any = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #ec4d7b;
  cursor: pointer;
  margin-left: 8px;
`
const Author = styled(ProfileCard)`
  margin-bottom: 7px;
`
const Text = styled.div`
  margin-bottom: 12px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: ${props => props.theme.colors.secondary};
  white-space: pre-wrap;
`
const voteIconStyles = css`
  cursor: pointer;
  stroke: #666666;
  ${(props: any) => {
    if (props.highlight) {
      return css`
        fill: ${props.theme.colors.primary};
        stroke: ${props.theme.colors.primary};
      `
    }
  }}
`
const ThumbsUpIcon = styled(FaRegThumbsUp)`
  ${voteIconStyles};
`
const ThumbsDownIcon = styled(FaRegThumbsDown)`
  ${voteIconStyles};
`
const UploadPreview = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`
const iconStyles = css`
  stroke: #666;
  cursor: pointer;
`
const EditIcon: any = styled(FaRegEdit)`
  ${iconStyles}
`
const TrashIcon: any = styled(FaRegTrashAlt)`
  ${iconStyles};
  margin-left: 12px;
`
const VoteButtonWrapper: any = styled.div`
  display: flex;
  align-items: center;
  user-select: none;
`
interface VoteButtonProps {
  onClick?: Function
  count: number
  icon: React.ReactNode
}
const VoteButton: React.FC<VoteButtonProps> = props => {
  return (
    <VoteButtonWrapper onClick={props.onClick}>
      {props.icon} <FooterText>{props.count}</FooterText>
    </VoteButtonWrapper>
  )
}

const getTimestamp = (isoDateString: string) => {
  const d = new Date(isoDateString)
  let dateFormat = ", h:m a"
  if (!isYesterday(d)) {
    dateFormat = "MMM d yyyy" + dateFormat
    return format(d, dateFormat)
  }
  return "Yesterday, " + format(d, dateFormat)
}

interface Props {
  createdByLoggedInUser?: boolean
  editable?: boolean
  deletable?: boolean
  onEdit: Function
  onDelete: Function
}
const Reply: React.FC<ForumPost & Props> = props => {
  const timestamp = props?.lastUpdatedOn
    ? getTimestamp(props?.lastUpdatedOn)
    : ""
  let authorName = props.author?.name
  if (props.createdByLoggedInUser) {
    authorName += " (You)"
  }

  useEffect(() => {
    Amplify.configure(AWS_CONFIG)
  }, [])

  return (
    <Container>
      {/* @ts-ignore */}
      <Author
        imageStyle={{
          border: "1.5px solid #00B7A2",
        }}
        name={props.author?.name}
        title={authorName}
        subtitle={timestamp}
      />
      <Content>
        <Text>{props.text}</Text>
        {props.attachments && props.attachments.length ? (
          <UploadPreview>
            {props.attachments?.map((file: any, i: number) => {
              return <FilePreview key={i.toString()} data={file} />
            })}
          </UploadPreview>
        ) : null}
        <Footer>
          <FooterSection>
            {props.editable && (
              <EditIcon onClick={() => props.onEdit(props.id)} />
            )}
            {props.deletable && (
              <TrashIcon onClick={() => props.onDelete(props.id)} />
            )}
          </FooterSection>
        </Footer>
      </Content>
    </Container>
  )
}

export default Reply
