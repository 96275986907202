import Layout from "components/layout"
import SEO from "components/seo"
import React from "react"
import { Router } from "@reach/router"
import ThreadPage from "components/threadPage"

const ThreadForum = () => {
  return (
    <Layout>
      <SEO title="Discussion Forum" />
      <Router>
        <ThreadPage path="/thread/:id" />
      </Router>
    </Layout>
  )
}

export default ThreadForum
