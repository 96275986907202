import { IFile } from "models/IFile"
import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { HiDocumentText } from "react-icons/hi"
import { FaFilePdf } from "react-icons/fa"
import ModalVideo from "react-modal-video"
import EmbedVideoThumb from "components/EmbedVideoThumb"
import { captureImage } from "utils"

const Container = styled.div`
  cursor: pointer;
  color: ${props => props.theme.colors.primary};
  font-size: 12px;
  font-family: Open Sans;
  display: flex;
  flex-direction: column;
  margin-right: 16px;
  position: relative;
  .remove-file-btn {
    display: none;
  }
  &:hover {
    .remove-file-btn {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
`
const PdfIcon = styled(FaFilePdf)`
  height: 42px;
  stroke: white;
  fill: ${props => props.theme.colors.primary};
`
const DocIcon = styled(HiDocumentText)`
  height: 42px;
  stroke: white;
  fill: ${props => props.theme.colors.primary};
`
const ImagePreview = styled.img`
  height: 40px;
  object-fit: cover;
  margin-bottom: 0;
`
const Text = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100px;
`
const RemoveBtn: any = styled.div`
  position: absolute;
  font-size: 12px;
  top: -6px;
  right: -6px;
  padding: 4px;
  color: white;
  background: black;
  border-radius: 50%;
  z-index: 100;
  height: 16px;
  width: 16px;
`

interface Props {
  data: IFile
  removable?: boolean
  onRemove?: Function
}

interface VideoProps {
  data: IFile
}

const FilePreview: React.FC<Props> = props => {
  const [icon, setIcon] = useState<any>(null)
  const { data } = props
  const [videoModalIsVisible, setVideoModalIsVisible] = useState(false)
  const [videoURL, setVideoURL] = useState("")
  const showVideo = (url: string) => {
    setVideoModalIsVisible(true)
    setVideoURL(decodeURIComponent(url))
  }

  useEffect(() => {
    if (data.type?.match("pdf")) {
      setIcon(<PdfIcon />)
    } else if (data.type?.match("doc")) {
      setIcon(<DocIcon />)
    } else if (data.type?.match("image")) {
      setIcon(<ImagePreview src={data.previewImage} />)
    } else if (data.type?.match("video")) {
      captureImage(data.previewImage as string, (url: any) => {
        setIcon(
          <EmbedVideoThumb
            customStyle={{ width: "120px", height: "76px" }}
            size="small"
            onClick={() => showVideo(data.previewImage as string)}
            imageUrl={url}
          />
        )
      })
    }
  }, [data])

  return (
    <Container title={data.filename}>
      {icon}
      <Text>{data.filename}</Text>
      {props.removable && (
        <RemoveBtn className="remove-file-btn" onClick={props.onRemove}>
          x
        </RemoveBtn>
      )}
      <ModalVideo
        url={videoURL}
        channel="custom"
        autoplay={false}
        isOpen={videoModalIsVisible}
        onClose={() => setVideoModalIsVisible(false)}
      />
    </Container>
  )
}

export default FilePreview
