import _ from "lodash"
import { Amplify, Storage } from "aws-amplify"
import { AWS_CONFIG } from "../../../aws-config"
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api-graphql"
import { PageProps } from "models/PageProps"
import React, { useEffect, useState } from "react"
import styled from "styled-components"
import Breadcrumbs from "components/Breadcrumbs"
import { H2, H3 } from "components/typography"
import Loader from "components/Loader"
import { Col, Row } from "react-bootstrap"
import Reply from "./Reply"
import { breakpoints } from "utils"
import ReplyInput from "./ReplyInput"
import { IFile } from "models/IFile"
import { UserType } from "api/types"
import FilePreview from "./FilePreview"
import ConfirmModal from "components/ConfirmModal"
import {
  apiGetSingleForumThread,
  apiPostForumPost,
  apiRemoveNewForumPost,
} from "api/queries"
import { ForumThread, ForumPost, ForumAttachment } from "api/types"
import { toast } from "react-toastify"
import toastError from "utils/toastError"
import ReplyModal from "./ReplyModal"
import getProfileFromSessionStorage from "components/QueryUserProfile/getProfileFromSessionStorage"

const Container = styled.div`
  margin-top: 27px;
  @media (max-width: ${breakpoints.desktop}px) {
    padding: 0 16px;
  }
`
const Title = styled(H2)`
  margin-top: 15px;
`
const SidebarTitle = styled(H3)`
  margin-bottom: 12px;
`
const Description = styled.p`
  margin-bottom: 36px;
  color: Gray;
  font-size: 14px;
`
const NoContent = styled.div`
  font-family: Open Sans;
  font-style: italic;
  font-size: 14px;
  color: ${props => props.theme.colors.secondary};
`
const Main = styled(Row)``
const Content = styled(Col)``
const Sidebar = styled(Col)``
const UploadPreview = styled.div`
  display: flex;
  align-items: center;
`

interface Props {
  // id: string
}

const ThreadPage: React.FC<PageProps & Props> = props => {
  const [thread, setThread] = useState<ForumThread>()

  // @ts-ignore
  //const [loggedInUser, setLoggedInUser] = useState<UserType | null>()
  const profileStr = getProfileFromSessionStorage()
  const loggedInUser = profileStr ? JSON.parse(profileStr) : null

  const [confirmModalIsVisible, setConfirmModalIsVisible] = useState<boolean>(
    false
  )
  const [replyModalVisible, setReplyModalVisible] = useState<boolean>(false)
  const [posted, setPosted] = useState(false)
  const [loading, setLoading] = useState(false)
  const [deleteReplyId, setDeleteReplyId] = useState<string>()
  const [editingReply, setEditingReply] = useState<ForumPost | null>(null)
  const breadcrumbs = [{ text: "Discussion Forum", link: "/discuss" }]
  const role =
    typeof window !== `undefined`
      ? window.sessionStorage.getItem("UserRole")
      : ""

  const updateThread = async (
    text: string,
    attachments: any,
    postId: string | undefined = undefined
  ) => {
    try {
      if (thread && thread.id) {
        setLoading(true)
        await apiPostForumPost(thread.id, {
          text,
          postId,
          threadTitle: thread?.title as string,
          parentId: thread.id,
          attachments,
        })
        await fetchForumThread(props?.id as string)
        setPosted(true)
        setLoading(false)
        onClose()
      } else {
        throw new Error("Thread id not available")
      }
      setLoading(false)
    } catch (err) {
      toastError(toast, `Something went wrong. ${err}`)
      setPosted(false)
      setLoading(false)
    }
  }

  const onReplySubmit = async (
    text: string,
    files: IFile[],
    postId: string | undefined = undefined
  ) => {
    const updatedThread = _.cloneDeep(thread)
    if (!text || !_.trim(text)) return
    if (!updatedThread) {
      alert("Thread is empty. Please try reloading the page")
      return
    }
    if (!loggedInUser || !loggedInUser.id) {
      alert(
        "Only logged in users can create posts. Please log in and start discussing with other women."
      )
      return
    }
    const attachments = files.map(file => ({
      mimeType: file.type,
      attachmentLocation: {
        bucket: AWS_CONFIG.Storage.AWSS3.bucket,
        region: AWS_CONFIG.Storage.AWSS3.region,
        key: file.key,
      },
    }))
    updateThread(_.trim(text), attachments, postId)
  }

  const onDeleteReplyConfirm = async () => {
    const updatedThread = _.cloneDeep(thread)
    if (!thread || !updatedThread) {
      alert("Thread is empty. Please try reloading the page")
      return
    }
    if (!loggedInUser || !loggedInUser.id) {
      alert("Only logged in users can delete posts. Please log in.")
      return
    }
    updatedThread.posts = _.filter(
      thread.posts,
      reply => reply?.id !== deleteReplyId
    )
    try {
      const result = await apiRemoveNewForumPost(deleteReplyId as string)
      if (result) {
        setConfirmModalIsVisible(false)
        setThread(updatedThread)
      }
    } catch (err) {
      console.error(err)
    }
  }
  const onDeleteReply = (id: string) => {
    setDeleteReplyId(id)
    setConfirmModalIsVisible(true)
  }
  const onEdit = (reply: ForumPost) => {
    setEditingReply(reply)
    setReplyModalVisible(true)
  }
  const onClose = () => {
    setEditingReply(null)
    setReplyModalVisible(false)
  }

  const renderReplies = () =>
    thread
      ? thread.posts?.map((reply: ForumPost | null, i: number) => {
          return (
            <Reply
              {...reply}
              key={i.toString()}
              editable={
                (loggedInUser && role?.toLowerCase() === "admin") ||
                reply?.author?.id === loggedInUser?.id
              }
              deletable={
                (loggedInUser && role?.toLowerCase() === "admin") as boolean
              }
              onDelete={onDeleteReply}
              onEdit={() => onEdit(reply as ForumPost)}
              createdByLoggedInUser={
                (loggedInUser &&
                  loggedInUser?.id === reply?.author?.id) as boolean
              }
            />
          )
        })
      : null

  const previewImageUrl = async (attachmentLocation: any) => {
    const { key } = attachmentLocation
    try {
      const photoUrl = await Storage.get(key)
      // const  blob = photoUrl.Body
      // const url = URL.createObjectURL(blob)
      return photoUrl
    } catch (e) {
      return ""
    }
  }

  const fetchForumThread = async (id: string) => {
    const data = await apiGetSingleForumThread(
      id,
      loggedInUser
        ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
        : GRAPHQL_AUTH_MODE.API_KEY
    )
    try {
      if (data.posts && data.posts.length) {
        const list = data?.posts.map(async item => {
          if (item?.attachments && item.attachments.length) {
            const attachments = item!.attachments!.map(async item => ({
              filename: item?.attachmentLocation?.key,
              type: item?.mimeType,
              previewImage: await previewImageUrl(item?.attachmentLocation),
              key: item?.attachmentLocation?.key,
            }))
            return {
              ...item,
              attachments: await Promise.all(attachments),
            }
          }
          return item
        })
        const result = (await Promise.all(list)) as ForumPost[]
        const posts = result.sort((a, b) =>
          a.createdOn! < b.createdOn! ? 1 : a.createdOn! > b.createdOn! ? -1 : 0
        )

        setThread({ ...data, posts })
      } else {
        setThread(data)
      }
    } catch (e) {
      console.log(e)
    }
  }
  useEffect(() => {
    try {
      Amplify.configure(AWS_CONFIG)
      // const userStr: any = sessionStorage.getItem("userData")
      // const user = JSON.parse(userStr)
      // setLoggedInUser(user || null)
      fetchForumThread(props?.id as string)
    } catch (err) {
      toastError(toast, "Something went wrong.")
    }
  }, [])

  let myFiles =
    thread && thread.posts && loggedInUser
      ? thread.posts?.filter((item: any) => {
          return (
            item.author.id === loggedInUser.id &&
            item.attachments &&
            item.attachments.length
          )
        })
      : null
  let sharedFiles
  if (myFiles && myFiles.length) {
    sharedFiles = myFiles.reduce((prev: any[], curr: ForumPost | null) => {
      prev.push(...(curr?.attachments as ForumAttachment[]))
      return prev
    }, [] as any[]) as any[]
  }

  if (!thread) {
    return (
      <Container>
        <Loader />
      </Container>
    )
  }

  return (
    <Container>
      <Breadcrumbs items={breadcrumbs} activeItem={{ text: thread?.title }} />
      <Title>{thread?.title}</Title>
      <Description>{thread.text}</Description>
      <Main>
        <Content>
          <ReplyInput
            posted={posted}
            loading={loading}
            onSubmit={onReplySubmit}
          />
          {renderReplies()}
        </Content>
        {/* <Sidebar md="4">
          <SidebarTitle>My Shared Files</SidebarTitle>
          {sharedFiles && sharedFiles.length > 0 ? (
            <UploadPreview>
              {sharedFiles.map((file: any, i) => {
                return <FilePreview data={file} key={i.toString()} />
              })}
            </UploadPreview>
          ) : (
            <NoContent>You haven’t shared any file yet...</NoContent>
          )}
        </Sidebar> */}
      </Main>
      <ConfirmModal
        title="Delete Reply"
        show={confirmModalIsVisible}
        onClose={() => setConfirmModalIsVisible(false)}
        onConfirm={onDeleteReplyConfirm}
      >
        Are you sure you want to delete this reply?
      </ConfirmModal>
      <ReplyModal
        posted={posted}
        loading={loading}
        show={replyModalVisible}
        onClose={onClose}
        onReplySubmit={onReplySubmit}
        reply={editingReply}
      />
    </Container>
  )
}

export default ThreadPage
