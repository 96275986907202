import MobileModal from "components/MobileModal"
import _ from "lodash"
import { Amplify } from "aws-amplify"
import { AWS_CONFIG } from "../../../aws-config"
import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { breakpoints } from "utils"
import Modal from "components/dashboardPage/Modal"
import ModalContent from "components/dashboardPage/Modal/ModalContent"
import ReplyInput from "./ReplyInput"

const Container = styled.div``
interface Props {
  show: boolean
  onClose: Function
  posted: boolean
  loading: boolean
  onReplySubmit: Function
  reply: any
}

const ReplyModal: React.FC<Props> = props => {
  useEffect(() => {
    Amplify.configure(AWS_CONFIG)
  }, [])

  const title = "Edit your Reply"

  const getContent = () => {
    return (
      <ModalContent
        title={title}
        subtitle={""}
        footer={null}
        onClose={props.onClose}
        bodyStyle={{ paddingTop: 0 }}
        containerStyle={{ padding: "0 10px" }}
      >
        <ReplyInput
          posted={props.posted}
          loading={props.loading}
          onSubmit={props.onReplySubmit}
          reply={props.reply}
        />
      </ModalContent>
    )
  }

  const isPhoneView =
    typeof window !== `undefined` && window.innerWidth <= breakpoints.phone

  if (isPhoneView) {
    return <MobileModal show={props.show}>{getContent()}</MobileModal>
  }

  return (
    <Container>
      <Modal show={props.show} onClose={props.onClose}>
        {getContent()}
      </Modal>
    </Container>
  )
}

export default ReplyModal
