import { Amplify, Storage } from "aws-amplify"
import { AWS_CONFIG } from "../../../aws-config"
import _ from "lodash"
import shortid from "shortid"
import React, { useState, useEffect } from "react"
import { Editor, EditorState, Modifier } from "draft-js"
import styled from "styled-components"
import "draft-js/dist/Draft.css"
import { BiUpload } from "react-icons/bi"
import { Button } from "components/buttons"
import EmojiPicker from "./EmojiPicker"
import { IFile } from "models/IFile"
import FilePreview from "./FilePreview"
import ClockLoader from "react-spinners/ClockLoader"
import { breakpoints } from "utils"
import theme from "components/layout/theme"

const Container = styled.div`
  background: rgba(0, 0, 0, 0.03);
  border-radius: 5px;
  margin-bottom: 24px;
  padding: 16px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  & > .DraftEditor-root {
    height: 100px;
    overflow: auto;
  }
`
const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`
const UploadButton = styled.div`
  position: relative;
  cursor: pointer;
`
const UploadIcon = styled(BiUpload)`
  margin-right: 24px;
  width: 20px;
  height: 20px;
  cursor: pointer;
  color: ${theme.colors.primary};
`
const UploadInput = styled.input`
  opacity: 0;
  position: absolute;
  width: 20px;
  height: 20px;
  z-index: 99;
  cursor: pointer;
`
const UploadPreview = styled.div`
  display: flex;
  align-items: center;
`
const FooterBtns = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: ${breakpoints.phone}px) {
    justify-content: space-between;
    width: 100%;
  }
`
const UploadWrapper = styled.div`
  display: flex;
`

const LoadingButton = styled(Button)`
  display: flex;
  align-items: center;
  div {
    margin-right: 4px;
  }
`

interface Props {
  onSubmit?: Function
  posted: boolean
  loading: boolean
  reply?: any
}

const insertText = (text: string, editorState: EditorState) => {
  const currentContent = editorState.getCurrentContent()
  const currentSelection = editorState.getSelection()

  const newContent = Modifier.replaceText(
    currentContent,
    currentSelection,
    text
  )

  const newEditorState = EditorState.push(
    editorState,
    newContent,
    "insert-characters"
  )
  return EditorState.forceSelection(
    newEditorState,
    newContent.getSelectionAfter()
  )
}

const ReplyInput: React.FC<Props> = props => {
  const [files, setFiles] = useState<IFile[]>([])
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  )
  const [previewImageList, setPreviewImageList] = useState<string[]>([])

  const sendTextToEditor = (text: string) => {
    setEditorState(insertText(text, editorState))
  }
  const onPostBtnClick = () => {
    const text = editorState.getCurrentContent().getPlainText()
    props.onSubmit &&
      props.onSubmit(text, files, props.reply ? props.reply.id : undefined)
  }
  const onEmojiSelect = (emoji: any) => {
    sendTextToEditor(emoji.native)
  }
  const onUpload = async (e: any) => {
    const file: any = e.target.files[0]
    const fileTypes = [
      ".jpg",
      ".jpeg",
      ".png",
      ".pdf",
      ".doc",
      ".docx",
      ".avi",
      ".wmv",
      ".mpeg",
      ".mp4",
      ".m4v",
      ".mov",
    ]
    const fileExt = file.name.substring(file.name.lastIndexOf("."))
    if (!fileTypes.includes(fileExt)) {
      alert("Invalid file selected.")
      return
    }

    const newFiles: IFile[] = _.cloneDeep(files)
    const fileReader = new FileReader()
    const id = shortid()

    try {
      const result: any = await Storage.put(file.name, file, {})
      if (file.type.match(/image|video/)) {
        fileReader.onload = function () {
          const previewImage: any = URL.createObjectURL(file)
          previewImageList.push(previewImage)
          setPreviewImageList([...previewImageList])
          newFiles.push({
            id,
            filename: file.name,
            type: file.type,
            previewImage,
            key: result.key,
          })
          setFiles(newFiles)
        }
        fileReader.readAsDataURL(file)
      } else {
        newFiles.push({
          id,
          filename: file.name,
          type: file.type,
          // previewImage: "",
          key: result.key,
        })
        setFiles(newFiles)
      }
    } catch (e) {
      console.error(e)
    }
  }

  const onRemoveFile = (id: string) => {
    setFiles(files.filter(file => file.id !== id))
  }

  useEffect(() => {
    Amplify.configure(AWS_CONFIG)
  }, [])

  useEffect(() => {
    if (props.posted) {
      setEditorState(() => EditorState.createEmpty())
      setFiles([])
    }
  }, [props.posted])

  useEffect(() => {
    if (props.reply) {
      sendTextToEditor(props.reply.text)
      setFiles(props.reply.attachments)
    }
  }, [props.reply])

  useEffect(() => {
    return () => {
      previewImageList.forEach(item => {
        URL.revokeObjectURL(item)
      })
    }
  }, [])

  return (
    <Container>
      <Editor
        editorState={editorState}
        // @ts-ignore
        style={{ height: "100px" }}
        onChange={setEditorState}
        placeholder="Type your response here"
      />
      <Footer>
        <UploadPreview>
          {files.map(file => {
            return (
              <FilePreview
                key={file.id}
                data={file}
                removable
                onRemove={() => onRemoveFile(file.id as string)}
              />
            )
          })}
        </UploadPreview>
        <FooterBtns>
          {/* <UploadWrapper>
            <EmojiPicker onSelect={onEmojiSelect} />
            <UploadButton>
              <UploadInput
                onChange={onUpload}
                type="file"
                accept=".jpg,.jpeg,.png,.pdf,.doc,.docx,.avi,.wmv,.mpeg,.mp4,.m4v,.mov"
              />
              <UploadIcon />
            </UploadButton>
          </UploadWrapper> */}
          {props.loading ? (
            <LoadingButton>
              <ClockLoader size={16} color={"white"} loading={true} />
              <span>Post</span>
            </LoadingButton>
          ) : (
            <Button onClick={onPostBtnClick}>Post</Button>
          )}
        </FooterBtns>
      </Footer>
    </Container>
  )
}

export default ReplyInput
